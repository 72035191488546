import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { MdOutlineDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";

function List({ setValues, skip, data, count }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFIER);
  const LIST = t("LIST", { returnObjects: true });
  const QUALIFIERS = t("QUALIFIERS", { returnObjects: true });
  const columns = [
    {
      title: LIST.ID,
      dataIndex: "id",
    },
    {
      title: LIST.NAME,
      dataIndex: "name",
      render: (item) => QUALIFIERS[item],
    },
    {
      title: LIST.PRIORITY,
      dataIndex: "priority",
    },
    {
      title: LIST.DEFAULT,
      dataIndex: "isDefault",
      render: (value) => (value === true ? <MdOutlineDone size={16} /> : ""),
    },
    {
      title: LIST.BLOCKING,
      dataIndex: "isBlocking",
      render: (value) => (value === true ? <MdOutlineDone size={16} /> : ""),
    },
  ];

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        okText={LIST.HEADER.SAVE}
        cancelText={LIST.HEADER.REFRESH}
        hideButtons={false}
        onOkClick={() => navigate(SCREENS.PRIVATE.ADMIN.QUALIFIERS.CREATE.path)}
        onCancelClick={() => {}}
      />
      <Table
        dataSource={data}
        columns={columns}
        rowKey={"id"}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            return navigate(
              `${SCREENS.PRIVATE.ADMIN.QUALIFIERS.DETAIL.path}/${record.id}`
            );
          },
        })}
        scroll={{ x: true }}
      />
      <br />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
