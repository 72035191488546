import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { DOMAIN_SIGN_IN } from "utils/api/graphql/mutations/users";
import { LINK } from "utils/api/graphql/queries/link";
import { TRACER } from "utils/api/graphql/queries/tracers";
import { SCREENS, TRACER_TYPES } from "utils/constants";

const Init = () => {
  const navigate = useNavigate();
  const [signIn] = useMutation(DOMAIN_SIGN_IN);

  const [host, setHost] = useState(null);
  const { src } = useParams();
  const url = window.location.href;
  const hash = window.location.hash;
  const queryParams = new URLSearchParams(hash && hash?.split("?")[1]);
  const srcParam = queryParams.get("src");
  const [getTracer] = useLazyQuery(TRACER);
  const [getLink] = useLazyQuery(LINK);

  useEffect(() => {
    if (window.self === window.top) {
      const projectIdParams = queryParams.get("p");

      if (projectIdParams && projectIdParams !== "undefined") {
        localStorage.setItem("projectId", projectIdParams);
      }
      const cleanedBaseUrl = url
        .split("?")[0]
        .replace(/(^\w+:|^)\/\//, "")
        .replace(/\/$/, "");
      return setHost(cleanedBaseUrl);
    }
    window.addEventListener("message", (event) => {
      if (
        process.env.REACT_APP_PROCESS_FLOWS.split(",").includes(event.origin)
      ) {
        const { url: parentUrl } = event.data;
        const url = new URL(parentUrl);
        const queryParams = new URLSearchParams(url.search);
        const project = queryParams.get("p");
        const link = queryParams.get("l");
        const subscription = queryParams.get("s");
        const status = queryParams.get("status");
        if (project && project !== "undefined") {
          localStorage.setItem("projectId", project);
        }
        if (subscription && subscription !== "undefined") {
          localStorage.setItem("subscriptionId", subscription);
        }
        if (status && status !== "undefined") {
          localStorage.setItem("statusId", status);
        }
        if (link && link !== "undefined") {
          localStorage.setItem("linkId", link);
        }
        setHost(url.host);
      }
    });
  }, []);

  useEffect(() => {
    if (!host) return;
    signIn({
      variables: {
        data: {
          name: host,
        },
      },
      onCompleted: ({ domainSignIn }) => {
        localStorage.setItem("accessToken", domainSignIn?.accessToken);
        const linkIdParams = queryParams.get("l");
        const link = linkIdParams || localStorage.getItem("linkId");
        if (link && link !== "undefined") {
          return getLink({
            variables: { where: { id: link } },
            onCompleted: ({ link }) => {
              localStorage.setItem("projectId", get(link, "project.id"));
              localStorage.setItem(
                "subscriptionId",
                get(link, "subscription.id")
              );
              localStorage.setItem("statusId", get(link, "fields.status.id"));
              const projectId = localStorage.getItem("projectId");
              const subscriptionId = localStorage.getItem("subscriptionId");
              const statusId = localStorage.getItem("statusId");

              localStorage.removeItem("user");
              if (projectId && projectId !== "undefined") {
                return getTracer({
                  variables: {
                    where: { type: TRACER_TYPES.COMPARISON },
                  },
                  onCompleted: ({ tracer }) => {
                    const process = get(
                      omitDeep(tracer, "__typename"),
                      "flow.process"
                    );
                    navigate(
                      `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${projectId}`,
                      {
                        state: {
                          process,
                          tracer: omitDeep(tracer, "__typename"),
                          subscriptionId:
                            subscriptionId && subscriptionId !== "undefined"
                              ? subscriptionId
                              : undefined,
                          status: get(process, "processStatuses", []).find(
                            ({ id }) => id === statusId
                          ),
                        },
                      }
                    );
                  },
                });
              } else {
                localStorage.setItem("templateHistory", "{}");
                navigate(
                  `${SCREENS.PUBLIC.PROCESSES.CREATE.path}/${srcParam || src}`
                );
              }
            },
          });
        }
        const projectId = localStorage.getItem("projectId");
        const subscriptionId = localStorage.getItem("subscriptionId");
        const statusId = localStorage.getItem("statusId");

        localStorage.removeItem("user");
        if (projectId && projectId !== "undefined") {
          return getTracer({
            variables: {
              where: { type: TRACER_TYPES.COMPARISON },
            },
            onCompleted: ({ tracer }) => {
              const process = get(
                omitDeep(tracer, "__typename"),
                "flow.process"
              );
              navigate(
                `${SCREENS.PRIVATE.GUEST.PROJECTS.DETAIL.path}/${projectId}`,
                {
                  state: {
                    process,
                    tracer: omitDeep(tracer, "__typename"),
                    subscriptionId:
                      subscriptionId && subscriptionId !== "undefined"
                        ? subscriptionId
                        : undefined,
                    status: get(process, "processStatuses", []).find(
                      ({ id }) => id === statusId
                    ),
                  },
                }
              );
            },
          });
        } else {
          localStorage.setItem("templateHistory", "{}");
          navigate(
            `${SCREENS.PUBLIC.PROCESSES.CREATE.path}/${srcParam || src}`
          );
        }
      },
    });
  }, [host]);
};

export default Init;
